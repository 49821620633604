import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  InputLabel,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Chip,
  Switch,
  Box,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ArticleIcon from "@mui/icons-material/Article";
import { useParams } from "react-router-dom";
import "./VendorUserInfoUpdate.scss";
import NoAccess from "../NoAccess/NoAccess";
import * as _ from "lodash";
import { leadSquaredTrigger } from "shared/handlers/leadSquaredTrigger.handler";
import { useStyles } from "./vendorUserInfoUpdate.styles";
import { axiosHandler } from "shared/handlers/axios.handler";
import { APPROVE_DROPDOWN_OPTIONS, END_POINTS, INITIAL_USER_INFO_STATE } from "./vendorUserInfoUpdate.constants";
import {
  IUserInfo,
  IKeyOfUserInfo,
  IUserEditModeType,
  IUserInfoPostPayload,
  VerificationStatus,
} from "./vendorUserInfoUpdate.interfaces";
import { red, blue } from "@mui/material/colors";
import { DropDown } from "components/VendorCapabilitiesUpdate/components/DropDown";
import { Autocomplete } from "@material-ui/lab";
import COUNTRY_JSON from "../../constants/countryCode.json";
import { usePrompt } from "components/Modal/usePrompt";
import { checkUserHasOnlyVendorViewRole } from "shared/helpers/checkHasAccess";
import { notification } from "antd";

const VendorsUserInfoUpdate = () => {
  const classes = useStyles();
  const COUNTRY_PHONE_CODE_JSON = COUNTRY_JSON as { [key: string]: { code: string; dial_code: string; name: string } };
  const [loader, enableLoader] = useState(false);
  const [reloadUpdate, setReloadUpdate] = useState(false);
  const [userInfo, setUserInfo] = useState<IUserInfo>(INITIAL_USER_INFO_STATE);
  const { gstn } = useParams<{ gstn: string }>();

  const access = checkUserHasOnlyVendorViewRole()

  const [hasAccess, setHasAccess] = useState(access);
  const [emailInputError, setEmailInputError] = useState(false);
  const [firstNameInputError, setFirstNameInputError] = useState(false);
  const [mobileInputError, setMobileInputError] = useState(false);
  const [openWarningModal, setWarningModal] = useState(false);
  const [existingUsers, setExistingUsers] = useState<IUserInfo[]>([]); // for having reference of existing users from GET call
  const [users, setUsers] = useState<IUserInfo[]>(_.cloneDeep(existingUsers)); // for payload
  const [editMode, setEditMode] = useState<IUserEditModeType>("");
  const [selectedUserPhoneState, setSelectedUserPhoneState] = useState({
    countryCode: "IN",
    dial_code: "+91",
    phoneNumberWithoutDialCode: "",
  });
  // to have reference of latest primaryUser
  const [currentPrimaryUser, setCurrentPrimaryUser] = useState<IUserInfo | {}>({});
  const [primaryUserToggled, setPrimaryUserToggled] = useState(false);
  const prompt = usePrompt();

  /** Fetch existing Users */
  useEffect(() => {
    (async () => {
      enableLoader(true);
      axiosHandler({
        withAuth: true,
        axiosConfig: { method: "GET", url: END_POINTS.GET_USERS(gstn) },
        failureCallback: (err) => {},
        successCallback: (successResp) => {
          setExistingUsers(successResp?.data);
          resetPrimaryUserToggle();
        },
      });
      enableLoader(false);
    })();
  }, [reloadUpdate]);

  /** useEffect watches existingUsers */
  useEffect(() => {
    /** inject existing-users in state */
    const deepClonedExistingUsers = _.cloneDeep(existingUsers);
    setUsers(deepClonedExistingUsers);

    /** setting existing primary user */
    const existingPrimaryUser = deepClonedExistingUsers.find((user) => user?.primaryUser);
    if (existingPrimaryUser?.email) {
      setEditMode("UPDATE_EXISTING_USER");
      setUserInfo(existingPrimaryUser);
      setCurrentPrimaryUser(existingPrimaryUser);
    }
  }, [existingUsers]);

  useEffect(() => {
    /** when userInfo changes
     * - prepare values for phone number input field */
    if (userInfo?.phoneNumber?.startsWith("+91")) {
      // when existing phone number contains `+91`
      const [_, phoneNo] = userInfo?.phoneNumber?.split("+91");
      setSelectedUserPhoneState((prev) => ({ ...prev, phoneNumberWithoutDialCode: phoneNo }));
    } else {
      // when existing phone number does not contain `+91`
      setSelectedUserPhoneState((prev) => ({ ...prev, phoneNumberWithoutDialCode: userInfo?.phoneNumber || "" }));
    }
  }, [userInfo]);

  const postPayload = (PAYLOAD: IUserInfoPostPayload) => {
    enableLoader(true);
    axiosHandler({
      withAuth: true,
      axiosConfig: { method: "POST", url: END_POINTS.POST_USERS, data: PAYLOAD },
      successCallback: (successResp) => {
        if (successResp?.data?.status === "SUCCESS") {
          leadSquaredTrigger(gstn);
          setReloadUpdate((prev) => !prev);
          handleCloseWarningModal();
        }else{
          console.log("Unable to Update/Create/Delete User", successResp);
          notification.error({
            top: 70, 
            duration: 4,
            description: ``,
            message: successResp?.data?.message,
          });
          handleCloseWarningModal();
          enableLoader(false);
        }
      },
      failureCallback: (err) => {
        console.log("Unable to Update/Create/Delete User", err);
        notification.error({
          top: 70, 
          duration: 4,
          description: ``,
          message: err.message,
        });
        handleCloseWarningModal();
        enableLoader(false);
      },
    });
  };

  const removeUser = (userData: IUserInfo) => {
    userData.deleteUser = true;
    const { primaryUser, ...remainingProperties } = userData;
    postPayload({ currentUser: { ...remainingProperties } });
  };

  const isPayloadValid = (payload: IUserInfo) => {
    const isFirstNamePresent = payload?.firstName;
    isFirstNamePresent ? setFirstNameInputError(false) : setFirstNameInputError(true);
    const validateEmail = String(payload.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
    !validateEmail ? setEmailInputError(true) : setEmailInputError(false);

    const INDIAN_PHONE_NUMBER_REGEX = /^(\+91-|\+91)[6-9]\d{9}$/;
    const validatePhoneNumber = String(payload.phoneNumber).match(INDIAN_PHONE_NUMBER_REGEX);
    !validatePhoneNumber ? setMobileInputError(true) : setMobileInputError(false);

    return isFirstNamePresent && validateEmail && validatePhoneNumber ? true : false;
  };

  const addNewOrUpdateExistingUser = async (gstn: string, userData: IUserInfo, editModeType: IUserEditModeType) => {
    if (isPayloadValid(userData)) {
      switch (editModeType) {
        case "UPDATE_EXISTING_USER":
          const { primaryUser, ...remainingProperties } = userData;
          const userPayload: IUserInfoPostPayload = { currentUser: { ...remainingProperties } };
          if (primaryUserToggled) {
            userPayload.primaryUserChange = { from: currentPrimaryUser, to: userData };
          }
          postPayload(userPayload);
          break;

        case "CREATE_NEW_USER":
          const isExistingEmail = users.filter((user) => user?.email === userData?.email)?.length !== 0;
          const isExistingPhoneNumber =
            users.filter((user) => user?.phoneNumber === userData?.phoneNumber)?.length !== 0;
          const isFirstNamePresent = userData?.firstName;
          if (!isFirstNamePresent) setFirstNameInputError(true);
          if (isExistingEmail) setEmailInputError(true);
          if (isExistingPhoneNumber) setMobileInputError(true);
          if (!isExistingEmail && !isExistingPhoneNumber && isFirstNamePresent) {
            const newUser: IUserInfo = {
              ...userInfo,
              gstn,
              newUser: true,
              phoneNumber: userData?.phoneNumber,
              companyRole: userInfo?.companyRole || "",
              gstnVerificationStatus: VerificationStatus.documentPending,
            };
            const { primaryUser, ...remainingProperties } = newUser;
            const userPayload: IUserInfoPostPayload = { currentUser: { ...remainingProperties } };
            postPayload(userPayload);
          }
          break;

        default:
          break;
      }
    } else {
      enableLoader(false);
    }
  };

  const handleAddNewOrUpdateExistingUser = (gstn: string, userInfo: IUserInfo, editModeType: IUserEditModeType) => {
    const userInfoWithUpdatedPhoneNo = {
      ...userInfo,
      /** preparing user-data with phone number */
      phoneNumber: selectedUserPhoneState.dial_code + selectedUserPhoneState.phoneNumberWithoutDialCode,
    };
    addNewOrUpdateExistingUser(gstn, userInfoWithUpdatedPhoneNo, editModeType);
  };

  const doesStringContainOnlyNumbers = (val: string) => /^\d+$/.test(val);

  const handleInputChange = (value: string, key: IKeyOfUserInfo) => {
    let userInfoCopy = _.cloneDeep(userInfo);
    if (key === "email") {
      userInfoCopy["emailUpdated"] = true;
      userInfoCopy[key] = value.toLowerCase();
    } else if (key === "phoneNumber") {
      userInfoCopy["phoneNumberUpdated"] = true;
      userInfoCopy["phoneNumber"] = (() => {
        if (!value) {
          setMobileInputError(false);
          return "";
        }
        if (doesStringContainOnlyNumbers(value)) {
          setMobileInputError(false);
          // when entered value is a number
          return value;
        } else {
          // when entered value is not a number
          return userInfoCopy["phoneNumber"];
        }
      })();

    } else {
      userInfoCopy[key] = value;
    }
    setUserInfo(userInfoCopy);
  };

  const resetErrors = () => {
    setFirstNameInputError(false);
    setMobileInputError(false);
    setEmailInputError(false);
    setEditMode("");
  };

  const resetPrimaryUserToggle = () => setPrimaryUserToggled(false);

  const resetUserInfo = () => {
    setUserInfo(INITIAL_USER_INFO_STATE);
    resetPrimaryUserToggle();
  };

  const handleCloseWarningModal = () => {
    setWarningModal(false);
    resetUserInfo();
    resetErrors();
  };

  const handleOnChipClick = (userData: IUserInfo) => {
    resetErrors();
    setUserInfo(userData);
    setEditMode("UPDATE_EXISTING_USER");
    resetPrimaryUserToggle();
  };

  const handleCloseEditMode = () => {
    resetUserInfo();
    resetErrors();
    setEditMode("");
  };

  const handleOnChipCloseClick = (userData: IUserInfo) => {
    setWarningModal(true);
    setUserInfo(userData);
    resetPrimaryUserToggle();
  };

  const handleOnClickAddNewUser = () => {
    resetErrors();
    resetUserInfo();
    setEditMode("CREATE_NEW_USER");
  };

  if (!hasAccess) {
    return <NoAccess />;
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container-fluid scrollable-container">
        <div className="row mt-4">
          <InputLabel className={classes.subHeader}>Users</InputLabel>
          <div className="col text-right">
            <Button
              variant="contained"
              className="ml-2 new_add_button mb-2"
              startIcon={<AddIcon />}
              onClick={handleOnClickAddNewUser}
            >
              Add New User
            </Button>
          </div>
          <div className={classes.chipContainer}>
            {Boolean(users?.length) &&
              users?.map((user) => {
                return (
                  <Chip
                    size="small"
                    key={user.email}
                    label={user.email}
                    className={classes.chip}
                    icon={<AccountCircleIcon />}
                    onClick={() => handleOnChipClick(user)}
                    onDelete={users?.length === 1 || user?.primaryUser ? undefined : () => handleOnChipCloseClick(user)}
                    color={user?.primaryUser ? "primary" : "default"}
                    style={{
                      border: userInfo?.email === user?.email ? `3px solid ${blue[200]}` : "",
                    }}
                  />
                );
              })}
          </div>
        </div>
        {editMode && (
          <Box
            display="flex"
            marginTop="1rem"
            padding="15px"
            width="100%"
            borderRadius="4px"
            flexDirection="column"
            justifyContent="flex-end"
            border="1px solid #c4c4c4"
          >
            {/* <Box alignSelf={"flex-end"}>
              <IconButton onClick={handleCloseEditMode}>
                <HighlightOffRoundedIcon />
              </IconButton>
            </Box> */}
            <div className={classes.peopleInfoContainer}>
              <>
                <Box display={"flex"} alignSelf={"flex-start"} gridGap={"20px"}>
                  {editMode === "UPDATE_EXISTING_USER" && (
                    <>
                      {/* Primary User */}
                      <Button variant="outlined" className={classes.subHeader} disableRipple disableElevation>
                        Primary User
                        <Switch
                          color="primary"
                          checked={userInfo?.primaryUser || false}
                          onChange={(e, checked) => {
                            if (users?.length !== 1) {
                              if (!users?.find((user) => userInfo?.email === user?.email)?.primaryUser) {
                                /** when user being edited is existing but not as primary-user */
                                setUserInfo((prev) => ({ ...prev, primaryUser: checked }));
                                setPrimaryUserToggled(checked);
                              }
                            }
                          }}
                        />
                      </Button>
                      {!userInfo?.primaryUser && (
                        <Autocomplete
                          style={{ width: 200 }}
                          value={(() => {
                            const selectedValueObj = APPROVE_DROPDOWN_OPTIONS.find(
                              (item) => item.value === userInfo?.gstnVerificationStatus,
                            );
                            if (selectedValueObj) {
                              return selectedValueObj;
                            }
                            return { label: "Doc Pending", value: VerificationStatus.documentPending };
                          })()}
                          options={APPROVE_DROPDOWN_OPTIONS}
                          getOptionLabel={(option: any) => option?.label}
                          onChange={(e, valueObj) => {
                            prompt?.setIsDataChanged(true);
                            setUserInfo((prev) => ({
                              ...prev,
                              gstnVerificationStatus: valueObj?.value || VerificationStatus.documentPending,
                              newUserApproved: Boolean(
                                valueObj?.value && valueObj?.value === VerificationStatus?.verified,
                              ),
                              // when Approve Vendor status is changed to notVerified(Reject)
                              newUserRejected: Boolean(
                                valueObj?.value && valueObj?.value === VerificationStatus?.notVerified,
                              ),
                            }));
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size={"medium"} label={"Approve Vendor"} variant="outlined" />
                          )}
                        />
                      )}
                    </>
                  )}
                  {/* GST Document */}
                  {!userInfo?.primaryUser && editMode === "UPDATE_EXISTING_USER" && (
                    <>
                      {userInfo?.gstnCertificate?.file?.fileUrl ? (
                        <a target="_blank" rel="noopener noreferrer" href={userInfo?.gstnCertificate?.file?.fileUrl}>
                          <Tooltip title={<>{userInfo?.gstnCertificate?.name || ""}</>}>
                            <Box
                              p={"14px 20px"}
                              gridGap={"8px"}
                              border={`1px solid ${blue[200]}`}
                              borderRadius={"4px"}
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"flex-start"}
                            >
                              <ArticleIcon color="primary" />
                              <div>GST Document</div>
                            </Box>
                          </Tooltip>
                        </a>
                      ) : (
                        <Chip
                          label={"GST File Absent"}
                          style={{ background: red[300], color: "#fff", fontWeight: 600 }}
                        />
                      )}
                    </>
                  )}
                </Box>
                <div className="row mt-4">
                  {/* FirstName */}
                  <div className="col remove-padding-left">
                    <InputLabel className={classes.subHeader}>First Name</InputLabel>
                    <TextField
                      id="firstName"
                      value={userInfo.firstName || ""}
                      variant="outlined"
                      helperText={firstNameInputError ? "FirstName is required" : ""}
                      onChange={(e) => {
                        prompt?.setIsDataChanged(true);
                        handleInputChange(e.target.value, "firstName")
                      }}
                      fullWidth
                    />
                  </div>
                  {/* LastName */}
                  <div className="col">
                    <InputLabel className={classes.subHeader}>Last Name</InputLabel>
                    <TextField
                      id="lastName"
                      value={userInfo.lastName || ""}
                      variant="outlined"
                      onChange={(e) => {
                        prompt?.setIsDataChanged(true);
                        handleInputChange(e.target.value, "lastName")
                      }}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  {/* Email */}
                  <div className="col remove-padding-left">
                    <InputLabel className={classes.subHeader}>Email</InputLabel>
                    <TextField
                      id="email"
                      type="email"
                      error={emailInputError}
                      helperText={emailInputError ? "Existing/Invalid email" : ""}
                      value={userInfo.email || ""}
                      variant="outlined"
                      inputProps={{
                        style: { textTransform: "lowercase" },
                      }}
                      onChange={(e) => {
                        prompt?.setIsDataChanged(true);
                        handleInputChange(e.target.value, "email")
                      }}
                      fullWidth
                    />
                  </div>
                  {/* Phone Number */}
                  <div className="col">
                    <InputLabel className={classes.subHeader}>Phone Number</InputLabel>
                    <FormControl variant="outlined" className={classes.phoneInputContainer}>
                      {/* have kept dropdown in case of future functionality */}
                      {/* <Select
                        value={selectedUserPhoneState.countryCode}
                        className={classes.phoneCountryCodeDropdown}
                        error={mobileInputError}
                        onChange={(e) => {
                          if (e?.target?.value) {
                            const countryPhoneData = COUNTRY_PHONE_CODE_JSON[e?.target?.value as string];
                            setSelectedUserPhoneState((prev) => ({
                              ...prev,
                              countryCode: countryPhoneData.code,
                              dial_code: countryPhoneData.dial_code,
                            }));
                          }
                        }}
                      >
                        <MenuItem value="" disabled>
                          Country Code
                        </MenuItem>
                        {COUNTRY_PHONE_CODE_JSON &&
                          Object.keys(COUNTRY_PHONE_CODE_JSON)
                            ?.sort((a, b) => a?.localeCompare(b))
                            ?.map((countryCode: string) => (
                              <MenuItem
                                value={COUNTRY_PHONE_CODE_JSON[countryCode]?.code}
                              >{`(${COUNTRY_PHONE_CODE_JSON[countryCode]?.code}) ${COUNTRY_PHONE_CODE_JSON[countryCode]?.dial_code}`}</MenuItem>
                            ))}
                      </Select> */}
                      <TextField
                        fullWidth
                        id="phoneNumber"
                        variant="outlined"
                        error={mobileInputError}
                        value={selectedUserPhoneState.phoneNumberWithoutDialCode || ""}
                        onChange={(e) => {
                          prompt?.setIsDataChanged(true);
                          handleInputChange(e.target.value, "phoneNumber")
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">{selectedUserPhoneState.dial_code}</InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    {/* phone Number input field */}
                    {selectedUserPhoneState.phoneNumberWithoutDialCode && mobileInputError && (
                      <>
                        <Typography variant="caption" display="block" className={classes.phoneNumberErrorText}>
                          {mobileInputError ? "Existing/Invalid phone number" : ""}
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  {/* Designation */}
                  <div className="col remove-padding-left">
                    <InputLabel className={classes.subHeader}>Designation</InputLabel>
                    <TextField
                      fullWidth
                      id="companyRole"
                      variant="outlined"
                      value={userInfo?.companyRole || ""}
                      placeholder={"designation is optional..."}
                      onChange={(e) => {
                        prompt?.setIsDataChanged(true);
                        handleInputChange(e.target.value, "companyRole")
                      }}
                    />
                  </div>
                </div>
              </>
            </div>
            <div className="save-button-container">
              <Button
                variant="contained"
                color="primary"
                className="save-button"
                startIcon={<CloudUploadIcon />}
                onClick={() => {
                  prompt?.setIsDataChanged(false)
                  handleAddNewOrUpdateExistingUser(gstn, userInfo, editMode)
                }}
              >
                {editMode === "UPDATE_EXISTING_USER"
                  ? "Update User"
                  : editMode === "CREATE_NEW_USER"
                  ? "Create User"
                  : ""}
              </Button>
            </div>
          </Box>
        )}
      </div>
      {/* Warning Popup before removing a user */}
      <Dialog
        open={openWarningModal}
        onClose={handleCloseWarningModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Are you Sure you want to delete this User: " + `${userInfo?.email}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWarningModal} color="primary">
            Cancel
          </Button>
          <Button onClick={() => removeUser(userInfo)} color="primary" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VendorsUserInfoUpdate;
